import React from "react";

export default function Footer() {
  return (
    <footer>
          <div>
              <h1>hii</h1>
      </div>
    </footer>
  );
}
